import { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,Link,useNavigate } from "react-router-dom";
import MobileOtp from './LoginAuth/MobileOtp';
import PropsFunctionCall from './UserShop';
import AddC from './AddC';
import Header from './Header';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { MdEdit } from "react-icons/md";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { CloseButton, Row } from 'react-bootstrap';
import Resetpassword from './LoginAuth/Reset_password';
import DeleteShop from './DeleteShop';
import Modal from 'react-bootstrap/Modal';

export default function Profile()
{

  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";


  const navigate=useNavigate();
    const [inputs, setInputs] = useState({});

	const [buttonText, setButtonText] = useState();
const [address,setaddress,]=useState('');
const [shopname,setshopname]=useState();
const [shopurl,setshopurl]=useState();
const [username,setusername]=useState('');
const [getMobile,setMobile]=useState('');
const [getShopMobile,setShopMobile]=useState('');

const [areapincode,setareapincode]=useState('');
const [profileimg,setprofileimg]=useState('');
const [getimage, setimage]=useState('')
const[getshopimage, setshopimage]=useState('')
const [city,setcity]=useState('');
const [shopAddress,setShopAddress]=useState('');
const [getdisimage, setdisimage]=useState('')
const img=useRef('')
const addr=useRef('')
const usrna=useRef('')
const [show, setshow] = useState(true)
const [getstatus, setstatus]=useState()
const [getenable, setdisable]=useState(true)

const [getpdata,setpdata]=useState();
const [cityerror]=useState("Enter Place Name")

const [showdel, setShowdel] = useState(false);
const [showdelacc, showdelaccount] = useState(false);
  
const [showmessage, setmessage]=useState(true)
const[getapistatus,setapistatus]=useState()

let getShopNameList
const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);
const handleShowaccount = () => showdelaccount(true);
const handlecloseaccount = () => showdelaccount(false);

const [getdelete, setdelete] = useState();
const [getcount, setcount] = useState(0);
let [deleteshopname,setdeleteshopname]=useState('')
let [deleteshopurl,setdeleteshopurl]=useState('')
const [counter, setcounter]=useState(1)
const[getprofiledetails,setprofiledetails]=useState([])
let [showshopimgmess,setshowshopimgmess]=useState(true)
const [getshowdetails, setshowdetails] = useState();
const [changedRow,setChangedRow]=useState('');
const [isMobileChanged, setIsMobileChanged] = useState(false);
const [isAddressChanged, setIsAddressChanged] = useState(false);
const [getusermobilenumber,setusermobilenumber]=useState('')

let imgsize=0;
let shopimagesize=0;
try{
shopimagesize=getshopimage.size
imgsize=getimage.size
}
catch{

}

let userData
console.log("showdetais",getshowdetails )
useEffect(()=>
{


	if(localStorage.getItem('user-info'))
	{
	  userData=localStorage.getItem('user-info')
		const details= JSON.parse(userData);
    let shoplocal=localStorage.getItem('shop-name')
    const shopjson= JSON.parse(shoplocal);
		// setshopname(shopjson)
    //console.log("shopprofle",shopjson)
		// setMobile(details.user_number)
    // setcity(details.City)
    
		// setusername(details.user_name)
    // setaddress(details.address)
  
    setdisimage(details.profile_img)
    setusermobilenumber(details.user_number)
    

		// setareapincode(details.user_areaCode)
		//console.log("details",details)
		//console.log("address:-"+address)
    // if(localStorage.getItem('profileimg'))
    // {
    //   setdisimage(localStorage.getItem('profileimg'))
    // }
	}
},[])


//console.log("address:-"+address)

	
    useEffect(()=>
       {
        if(getimage!=null && imgsize<5000000)
        {
          setdisable(false)
        }
        if(getshopimage!=null && shopimagesize<5000000)
        {
          setdisable(false)
        }
        if(isMobileChanged&&getShopMobile.length==10)
        {
          setdisable(false)
        }
        if(isAddressChanged)
        {
          setdisable(false)
        }
       }) 


       function updateProfileDetails()
       {
        
       const  formData= new FormData();
       formData.append('address',address)
       formData.append('city',city)
       formData.append('username',username)
       formData.append('getMobile',getMobile)
       formData.append('getshowdetails',getshowdetails)
       let data={address,city,username,getMobile,getshowdetails}
       //console.log("profiledata",data)
   //console.log("reactui",formData)
   axios.post(window.siteUrl+'/updateprofiledetails',data,
    //axios.post('//allstoresprice.com:3443/updateprofiledetails',data,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  })
    
   .then(res=>
     {
       setstatus(res.status)
     }, 
   res1=>console.log("st123",res1))
    
   .catch(err=>console.log(err))
   
   setTimeout(() => {
     
     setshow(false)
     
   }, 4000)
   }

   function shopimageupload(mobile,address,image,shopdata)
    {
      console.log("data",mobile,address,image,shopdata)
    const  formData= new FormData();
    formData.append('getimage',getshopimage)
    formData.append('shopurl',shopdata)
    formData.append('shopmobile',mobile)
    formData.append('shopaddress',address)
    //console.log("shopurlname",shopdata)

    axios.post(window.siteUrl+'/addshopimg',formData,
//axios.post('//allstoresprice.com:3443/addshopimg',formData,
{
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  foldername:shopdata,
  mobnumber:getusermobilenumber

}
})
.then(res=>
  {
    // localStorage.setItem("profileimg",res.data.img)
    setstatus(res.status)
  }, 
res1=>console.log("shop img",res1))
.catch(err=>console.log(err))

setTimeout(() => {
  
  setshowshopimgmess(false)
  
  img.current.value='';
  
}, 4000)
}


    function uploadimg(e)
    {
      e.preventDefault()
    const  formData= new FormData();
    formData.append('getimage',getimage)
    formData.append('getMobile',getMobile)
    //console.log("imgdetails",formData)

    
axios.post(window.siteUrl+'/addprofileimg',formData,
 //axios.post('//allstoresprice.com:3443/addprofileimg',formData,
 {
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}
})
 
.then(res=>
  {
    localStorage.setItem("profileimg",res.data.img)
    setstatus(res.status)
    
  }, 
res1=>console.log("st1",res1))
.catch(err=>console.log(err))

setTimeout(() => {
  
  setshow(false)
  
  img.current.value='';
  
}, 4000)
}

function deleteAction(idname)
{
//  const filepath= id.path
//  const filesplit= filepath.split("/")
//  const filename=filesplit[1]

const data=idname
//console.log("delete data", data)

fetch(`${window.siteUrl}/deleteshop/${data}`,
//fetch(`//allstoresprice.com:3443/deleteshop/${data}`,


{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
{
  setapistatus(result.status)
      setTimeout(() => {
  
        setmessage(false)
      },1000)
  
result.json().then((resp)=>
{
 //console.log(resp)
 handleClose()
 setcounter(counter+1)
 updateproductstatusonshopdelete()

})
})
}
function updateproductstatusonshopdelete()
       {
        
       const  formData= new FormData();
       
       formData.append('deleteshopurl',deleteshopurl)
       let data={deleteshopurl}
       //console.log("profiledata",data)
   //console.log("reactui",formData)

   
axios.post(window.siteUrl+'/updateproductstatusonshopdelete',data,
    //axios.post('//allstoresprice.com:3443/updateproductstatusonshopdelete',data,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  })
    
    
   .then(res=>
     {
       setstatus(res.status)
     }, 
   res1=>console.log("st123",res1))
   .catch(err=>console.log(err))
   
  //  setTimeout(() => {
     
  //    setshow(false)
     
  //  }, 4000)
   }
function updateproductstatus()
       {
        
       const  formData= new FormData();
       formData.append('getMobile',getMobile)
       
       let data={getMobile}
       //console.log("profiledata",data)
   //console.log("reactui",formData)

   
axios.post(window.siteUrl+'/updateproductstatus',data,
    //axios.post('//allstoresprice.com:3443/updateproductstatus',data,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  })
    
    
   .then(res=>
     {
       setstatus(res.status)
     }, 
   res1=>console.log("st123",res1))
   .catch(err=>console.log(err))
   
  //  setTimeout(() => {
     
  //    setshow(false)
     
  //  }, 4000)
   }

   function updateshostatusondeleteaccount()
       {
        
       const  formData= new FormData();
       formData.append('getMobile',getMobile)
       
       let data={getMobile}
       //console.log("profiledata",data)
   //console.log("reactui",formData)
   
axios.post(window.siteUrl+'/updateshopstatusondeleteofaccount',data,
   // axios.post('//allstoresprice.com:3443/updateshopstatusondeleteofaccount',data,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  })
    
    

   .then(res=>
     {
       setstatus(res.status)
     }, 
   res1=>console.log("shop",res1))
   .catch(err=>console.log(err))
   
  //  setTimeout(() => {
     
  //    setshow(false)
     
  //  }, 4000)
   }
function deleteAccountAPI()
{
//  const filepath= id.path
//  const filesplit= filepath.split("/")
//  const filename=filesplit[1]
userData=localStorage.getItem('user-info')
		const details= JSON.parse(userData);
const data=details.user_number
//console.log("delete data", data)

//fetch(`//allstoresprice.com:3443/deleteaccount/${data}`,
fetch(`${window.siteUrl}/deleteaccount/${data}`,


{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
//       setTimeout(() => {
  
//         setmessage(false)
//       },1000)
  
result.json().then((resp)=>
{
  updateproductstatus()
  updateshostatusondeleteaccount()
 console.log(resp)
 handlecloseaccount()
 setcounter(counter+1)
 localStorage.clear()
navigate("/")

 })
 })
}

let [getvalue,setvalue]=useState()
function setdeldata(id)
{
handleShow ()
  setvalue(id)

}
function deleteAccount()
{
  handleShowaccount ()
  

}

useEffect(()=>{
  if(localStorage.getItem('user-info')){
    let userdata=localStorage.getItem('user-info')
    let parseVal=JSON.parse(userdata)
    let usernumber= parseVal.user_number
    let userID=parseVal.cus_id
    console.log("shopname123",userID)

    

    fetch(`${window.siteUrl}/getshoplist/${userID}`,
  //fetch(`//allstoresprice.com:3443/getshoplist/${usernumber}`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
  
   
 {
   result.json().then((resp)=>{
     //console.log("shop list",resp)
    //  setcount(getplist.length) 
        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
        setshopname(resp)
        // setShopMobile(resp.user_number)
         localStorage.setItem('shop-name',JSON.stringify(resp))  
   }) 
 }
 )
}
},[counter])

useEffect(()=>{
  if(localStorage.getItem('user-info')){
    let userdata=localStorage.getItem('user-info')
    let parseVal=JSON.parse(userdata)
    let usernumber= parseVal.user_number
    
    fetch(`${window.siteUrl}/getuserdetails/${usernumber}`,
  //fetch(`//allstoresprice.com:3443/getuserdetails/${usernumber}`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
  
 {
   result.json().then((resp)=>{
     console.log("user details",resp[0])
    //  setcount(getplist.length) 
        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
        
        setprofiledetails(resp[0])
        setusername(resp[0].user_name)
        if(resp[0].address!=null){
        setaddress(resp[0].address)
        }
        if(resp[0].City!=null){
        setcity(resp[0].City)
        }
         setshowdetails(resp[0].showDetails)
        setMobile(resp[0].user_number)
        setareapincode(resp[0].user_areaCode)
        setprofileimg(resp[0].profile_img)
        

        //  localStorage.setItem('shop-name',JSON.stringify(resp))  
   }) 
 }
 )
}
},[]) 

return(
	<>
	{/* <AddC/> */}
<Header/>

<Container fluid='md ' className='my-2 ' style={{backgroundColor: "#f1f2eb"}}>
<Accordion defaultActiveKey="0" >
<Accordion.Item eventKey="0" >
        <Accordion.Header >Profile Details</Accordion.Header>
        <Accordion.Body>
       
<Row>
{
          getstatus==200 && show!=false?
      <label for="floatingInput" className='text-success fw-bold'>Updated successfully</label>
      :null
        }
        <Col xs={12}sm={4}>
       
         {/* <Card className='cardheightprof'>  */}

		<Card.Img className ="p-1 d-flex justify-content-center imagehprof"variant="top" src={profileimg}/>
      <Card.Body>
        </Card.Body>
        {/* </Card> */}
		<Form.Group controlId="formFile" className="mb-2 my-3">
        <Form.Label>Upload profile image</Form.Label>
        <Form.Control className='border border-dark'controlId="profileimgc" id="profileimg"type="file" ref={img} onChange={(e)=>setimage(e.target.files[0])}/>
      </Form.Group>
      {imgsize>5000000?
        <label for="floatingInput" className='text-danger'>Size should not be more than 5 mb</label>
        :
        null
        
      }
	  <Button className="my-2"disabled={getenable}onClick={uploadimg}variant="primary" >Upload</Button>{' '} 
		</Col>
			<Col xs={12}sm={8}>
      
	{/* <form className='container formC col-sm-12 col-lg-12 col-md-12 col-12'> */}
<div className="form-group d-flex justify-content-center mr-auto p-1 ">
<div className="col-sm-12 col-lg-7 col-md-12 col-12  align-self-center textformat">
<label for="floatingInput" className='loginLb float-right'>Profile</label>
<div className="form-group my-2 ">
	      {/* <label for="name" className='text-left'>User name</label> */}
		  
	   </div>
	   <div className="form-group my-2">
	   {/* <Form.Label>Email address</Form.Label> */}
	   <Form.Floating className="mb-3">
        <Form.Control maxLength={20}
          id="usernameID"
          controlId="usernameidc"
          type="text"
          className='border border-dark'
          placeholder="User Name"
          value={username} onChange={(e)=>setusername(e.target.value)}
        />
         {username=="" ?
  <label for="floatingInput" className='text-danger'>Enter User Name</label>
  :
  <label htmlFor="floatingInputCustom">Enter User Name</label>
}
      </Form.Floating>
     
	      {/* <input type="address" className="form-control" id="shopName" /> */}
	   </div>   
	   <div className="form-group my-2">
			   
		<Form.Floating className="mb-3">
        <Form.Control
          id="mobilenumberid"
          controlI="mobilenumberidc"
          type="text"
          className='border border-dark'
          placeholder="Mobile number"
          value={getMobile}
        />
        <label htmlFor="floatingInputCustom">Mobile Number</label>
        
      </Form.Floating>
	   {/* <label for="name" className="form-control d-flex justify-content-start" id="usernumber">{`Mobile Number ${mobilenumber}`}</label>  */}
	   </div>
	   <div className="form-group my-2">
	   <Form.Floating className="mb-3">
        <Form.Control
          id="areapinid"
          controlId="areapinidc"
          type="text"
          className='border border-dark'
          placeholder="Area Pin Code"
          value={areapincode}
        />
        <label htmlFor="floatingInputCustom">Area Pin Code</label>
      </Form.Floating>
	   {/* <label for="name" className="form-control d-flex justify-content-start" id="areaPin">{`Area Pin Code:-${areapincode}`}</label>  */}
	   </div>

	   <div className="form-group my-2">
	   {/* <Form.Label>Email address</Form.Label> */}
	   <Form.Floating className="mb-3">
        <Form.Control maxLength={20}
          id="cityid"
          controlId="cityidc"
          type="text"
          className='border border-dark'
          placeholder="Place"
          value={city} onChange={(e)=>setcity(e.target.value)}
        />
        {console.log("cityeror",city=="")}
        {city==""?
  <label for="floatingInput" className='text-danger'>Enter Place Name</label>
  :
  <label htmlFor="floatingInputCustom">Enter Place Name</label>
}
       
      </Form.Floating>
      
	      {/* <input type="address" className="form-control" id="shopName" /> */}
	   </div>  
     <div className="form-group my-2">
	   <FloatingLabel controlId="floatingTextarea2 mb-3" >
        <Form.Control
        id="addressid"
        controlId="addressidc"
          as="textarea"
          className='border border-dark'
          placeholder="Leave a comment here" 
		  value={address} onChange={(e)=>setaddress(e.target.value)}
          style={{ height: '150px' }}>{``}</Form.Control>
        
        {address==""?
  <label for="floatingInput" className='text-danger p-3'>Enter Address Details</label>
  :
  <label htmlFor="floatingInputCustom" className='p-3'>Enter Address Details</label>
}
<Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Show Mobile number and Address on Home Page"
        checked={getshowdetails}
        onClick={(e)=>setshowdetails(e.target.checked)}
        
      />
      {/* <Form.Check // prettier-ignore
        type="checkbox"
        id="default-checkbox"
        label="Do you want to delete your Account"
        
        
        
      /> */}
      {/* {console.log("checkdetails",getshowdetails)} */}
        {/* <div>
      <input type="checkbox" id="checkbox" checked={getshowdetails} />
      <label htmlFor="checkbox">I agree to Terms of Service </label>
      
    </div> */}
      
      
      </Form>
      </FloatingLabel>
      {console.log("check",getshowdetails)}
      </div> 
	   
       <Button className="my-2"onClick={updateProfileDetails}variant="primary">Update</Button>{' '} 
       <Button className="my-2"onClick={deleteAccount}variant="danger">Delete Account</Button>{' '} 
</div>
</div>
{/* </form> */}

</Col>
</Row>
</Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="1">
        <Accordion.Header>Shop Details</Accordion.Header>
        {
          getapistatus==200 && showmessage!=false?
      <label for="floatingInput" className='text-danger fw-bold'>Record deleted successfully</label>
      :null
        }
        {/* {
          getstatus==200 && showshopimgmess!=false?
      <label for="floatingInput" className='text-success fw-bold'>Updated successfully</label>
      :null
        } */}
        <Accordion.Body>
          
        {shopname&&shopname.length>0?
		<Table striped bordered hover responsive="sm" size="sm" >
      <thead >
        <tr >
          <th>Shop_image</th>
          <th>Shop_Name</th>
          <th>Shop_url</th>
          <th>Mob.Number</th>
          <th>Shop_address</th>
          <th>upload shop Img</th>
          <th>Action</th>
                    
        </tr>
      </thead>
      
      <tbody>
      {shopname &&
      shopname.length > 0 &&
      shopname.map((item, i)=>
      <>
      {console.log("shopname123",shopname)}
        <tr>
          <td>
          {/* <Card.Img className ="p-1 d-flex justify-content-center imagehshop"variant="top" src={item.shop_img}/> */}
      
          <Card.Img
  className="p-1 d-flex justify-content-center imagehshop"
  variant="top"
  src={changedRow === item.id ? (getshopimage ? URL.createObjectURL(getshopimage) : item.shop_img) : item.shop_img}
/>
      <Card.Body>
        </Card.Body>
        {/* </Card> */}
		
          </td>
          <td>{item.shop_name}</td>
          <td>{item.shop_url}</td>
          <td className="text-center align-middle">
          <input style={{ width: '150px' }}
  onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
  onChange={(e) => {
    setShopMobile(e.target.value);
    setChangedRow(item.id);
    setIsMobileChanged(true)

  }}
  type="number"
  placeholder="Enter Mobile number"
  className="form-control m-3 text-center fs-7 mobileNumber"
  id={`typePhone-${item.id}`} // added unique id
  value={
    changedRow === item.id
      ? getShopMobile !== '' ? getShopMobile : item.user_number
      : item.user_number
  }// updated value
  name="phone"
/>
{changedRow === item.id && getShopMobile.length >= 4 && getShopMobile.length < 10 ? (
  <label htmlFor={`floatingInput-${item.id}`} className="text-danger">
    Enter mobile number
  </label>
) : null}
          </td>
          
          <td>
            
          <div className="form-group my-2">
	   {/* <Form.Label>Email address</Form.Label> */}
	   <Form.Floating className="mb-3">
        <Form.Control maxLength={20}
          id={`typePhone-${item.id}`}
          controlId="addressidc"
          type="text"
          className='border border-dark'
          placeholder="Place"
          value={
            changedRow === item.id
      ? shopAddress !== '' ? shopAddress : item.address
      : item.address
        } onChange={(e)=>{setShopAddress(e.target.value);
        setChangedRow(item.id);
        setIsAddressChanged(true)}}
        />
      <label htmlFor="floatingInputCustom">Enter Place</label>
      {console.log("address",shopAddress)}
        {/* {shopAddress==""?
  <label for="floatingInput" className='text-danger'>Enter Place Name</label>
  :
  <label htmlFor="floatingInputCustom">Enter Place Name</label>
} */}
      </Form.Floating>
	   </div>  
            
        </td>
          
          <td>
            {/* <Form.Group controlId="formFile" className="mb-2 my-3 imagehshop"> */}
        {/* <Form.Label>Shop Image</Form.Label> */}
        
        {/* <Form.Group controlId="shopimgfile" className="mb-2 my-3">
	  <Form.Control controlId="floatingimgc"id="floatingimg" size="sm"  type="file" ref={img} onChange={(e)=>{setshopimage(e.target.files[0]);setChangedRow(item.id)}} />
      </Form.Group> */}


<Form.Group controlId="shopimgfile" className="mb-2 my-3">
  <Form.Control
    controlId="floatingimg"
    id="floatingimg"
    size="sm"
    type="file"
    ref={img}
    onChange={(e) => {
      const file = e.target.files[0];
      setshopimage(file ? file : item.shop_img);
      setChangedRow(item.id);
    }}
  />
</Form.Group>
        {/* </Form.Group> */}
	  {/* <Button size="sm"className="my-2"disabled={getenable} onClick={()=>shopimageupload(item.shop_url)}variant="primary" >Upload</Button>{' '}  */}
    {shopimagesize>5000000?
        <label for="floatingInput" className='text-danger'>Size should not be more than 5 mb</label>
        :
        null
        
      }
          </td>
          <td>
           
          <div><CloseButton  onClick={()=>setdeldata(item.id,setdeleteshopname(item.shop_name),setdeleteshopurl(item.shop_url))}/></div>
            <Button size="sm"className="my-2 bg-success"disabled={changedRow !== item.id || getenable} onClick={()=>
              
              {
                const mobile = getShopMobile!=''?getShopMobile:
                item.user_number;
                const address= shopAddress!=''?shopAddress:item.address
                const image= getshopimage
                shopimageupload(mobile,address,image,item.shop_url);}} >Save</Button>{' '} 
           
          </td>
        </tr>
        </>
)}
        </tbody>
        
        
          
    </Table>
    
    :<span className='"d-flex justify-content-center"'>No Shop available</span>
  }
		</Accordion.Body>
		</Accordion.Item>
		<Accordion.Item eventKey="2">
        <Accordion.Header>Reset Password</Accordion.Header>
        <Accordion.Body >
		<Row>
<Resetpassword/>
</Row>
		
		</Accordion.Body>
		</Accordion.Item>
		{/* <Accordion.Item eventKey="3">
        <Accordion.Header>Shop Details</Accordion.Header>
        <Accordion.Body>


		</Accordion.Body>
		</Accordion.Item>
		<Accordion.Item eventKey="4">
        <Accordion.Header>Shop Details</Accordion.Header>
        <Accordion.Body>


		</Accordion.Body>
		</Accordion.Item> */}
</Accordion>

</Container>

<Modal show={showdel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete shop name <b>{deleteshopname}</b> shop?
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="primary" onClick={()=>deleteAction(getvalue)}>
            Delete
          </Button> 
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showdelacc} onHide={handlecloseaccount}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the account?
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="primary" onClick={deleteAccountAPI}>
            Delete
          </Button> 
          <Button variant="secondary" onClick={handlecloseaccount}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
</>
)



  
    
  

}