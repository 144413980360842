import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import shoping1 from './shoping1.jpg'
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Category from './Category';
import { useState } from 'react';
export default function SearchBox(props)
{
  const [getSearchKey, setSearchKey] = useState([])
console.log("getkey",getSearchKey)
return(
<>
    <Container fluid='md' className='my-1' >
      {/* <Row>
      <Col xs={2} md={2}>
          
          </Col>
        <Col xs={8} md={8}> */}
         <form className="d-flex justify-content-center my-1" style={{width: "80%", margin: "0 auto"}}>
  <input className="form-control me-2" type="search" onChange={(e)=>setSearchKey(e.target.value)} placeholder="Search Your Product/Shop" aria-label="Search"/>
</form>
        
  
        {/* </Col> */}
        {/* <Col xs={2} md={2}>
          <div className='d-flex justify-content-start'> 
        
         </div> 
          </Col>
      </Row> */}
      {/* <Category/> */}
    </Container>
{props.inputKey(getSearchKey)}
</>

)

}