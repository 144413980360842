import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import shoping1 from './1.jpg'
import { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import Toastnot from './Toastnot';
import { NULL } from 'sass';


export default function ShopBannerAds(props)
{
  let [pageCount,getpageCount]=useState('');
  const [fullData, setFullData] = useState([]);
  const [loginusernumber, setloginusernumber]=useState()
  const [loginusername, setloginusername]=useState('')
    const [loginusercity, setloginusercity]=useState('')
    const [loginuseraddress, setloginuseraddress]=useState('')
    const [getcity,setcity]=useState('')
    
    const [getsearchkey,searchkey]=useState([])
    const [logincusid, setlogincusid]=useState('')
    const [getbanner, setbanner]=useState([])
    const[getposter, setposter]=useState([])
    const [getData,setData]=useState([]);


    const [getshoplist,setshoplist]=useState('');
  const [getbannerlist,setbannerlist]=useState('')
console.log("fullbanner",props)

  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

//   useEffect(()=>{
//     let pin=null
//     if(localStorage.getItem('user-info'))
//     {
//      const pinjson=localStorage.getItem('user-info')
//        pin=JSON.parse(pinjson).user_areaCode
//     }
//     const data={pin,getsearchkey}
//     const datas=JSON.stringify(data)
//     //console.log("dataapi",data)
//  //console.log("getsearchkey",getsearchkey)
//  //fetch(`85.31.232.240:8080/getproductsarea/${datas}`,
//   //fetch(`//allstoresprice.com:3443/getproductsarea/${datas}`,
//     fetch(`${window.siteUrl}/getproductsarea/${datas}`).then((result)=> 
//    {
//      result.json().then((resp)=>{
//       setFullData(resp)
//       // console.log("slice",resp)
//       // const data = resp.slice(0,12);
//     //   getpageCount(Math.ceil(resp.length / perPage));
//     // //console.log("page",Math.ceil(resp.length / perPage))
//     setData(data);
//        console.log("hlist1",resp)
       
//       //  setcount(getplist.length)
//         // localStorage.setItem('product-info',JSON.stringify(resp))
//         if(localStorage.getItem('user-info'))
//   {
//     try{
//     setcity(resp[0].city)
    
//     }
//     catch{

//     }
//     const loc=localStorage.getItem('user-info')
//  const parseVal=JSON.parse(loc)
//  setloginusernumber(parseVal.user_number)
//  setloginusername(parseVal.user_name)
// setloginusercity(parseVal.City)
// setloginuseraddress(parseVal.address)
// setlogincusid(parseVal.cus_id)


//   }
//      })
//    }
//    )
//  },[getsearchkey])
 
useEffect(()=>
{
  const shuffledList = props.fulldata.sort(() => Math.random() - 0.5);
  setbannerlist( shuffledList)
})


    const desk=4
  const mobile=2
      // var settings = {
      //     dots: true,
      //     infinite: true,
      //     speed: 300,
      //     slidesToShow: 4,
      //     slidesToScroll: 4,
      //     autoplay: true,
      //     autoplaySpeed: 3000,
          
  
      //   };
     
      return(
  <>
  
  <Container fluid className='  d-flex justify-content-center align-items-center headbanner' >
  {/* {props.city? <div className='text-start text-success fw-bold'>SHOPS IN {props.city.toUpperCase()}</div> :null} */}
  <Row xs={1} md={1} >
    <div className='text-white sliderim'>
    
      {getbannerlist && getbannerlist.length > 0 && (
        <Carousel indicators={false} controls={true} slide={false} style={{ width: '96vw', height: '350px', margin: '0 auto' , borderRadius: '20px !important',overflow: 'hidden'  }}>
          {getbannerlist.map((item, i)=> (
            
            item.imagetype=='poster'?
            
            <Carousel.Item key={i} style={{ height: '400px' }}  >  
            <a href={"/"+item.shopName}>
             
              {/* "https://m.media-amazon.com/images/I/71Ftzmh3XWL._AC_UY654_QL65_.jpg" */}
              <Card.Img className="bannerimg  my-1 p-1 "style={{ height: '250px'}} variant="top" src={`/${item.product_img}`} />
              
              <Carousel.Caption  className='text-secondary bannerAdstext '>
          <h3>{item.shop_name?item.shop_name.toUpperCase():null}</h3>
          <p>{item.product_desc}</p>
        </Carousel.Caption>
        </a>
            </Carousel.Item>
            :null
            
          ))}
        </Carousel>
      )}
    </div>
  </Row>
</Container>
  


  
  </>
      )
  }
