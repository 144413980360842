import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import shoping1 from './shoping1.jpg'
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from 'react';
import ToolTip from './ToolTip';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import ReactPaginate from 'react-paginate';
import {useParams,useNavigate,Link, useFetcher, json } from "react-router-dom";
import Likeview from './Likeview';
import OrderPlaced from './OrderPlaced';
import ProductDetails from './ProductDetails';

import AmazonCard from './AmazonCard';

export default function Holding(props)
{
 //const siteUrl="http://localhost:4500";
//const siteUrl="//allstoresprice.com:3443";


  const [result,setresult]=useState([])
  const params= useParams()
  const {name}=params
  const [getplist, setplist] = useState([]);
  const [getcount, setcount] = useState();
  const [page, setPage] = useState(1)
  const [data, setData] = useState([]);
  const [perPage] = useState(30);
  let [pageCount,getpageCount]=useState('');
  const [fullData, setFullData] = useState([]);
  const [loginusernumber, setloginusernumber]=useState()
  const [loginusername, setloginusername]=useState('')
    const [loginusercity, setloginusercity]=useState('')
    const [loginuseraddress, setloginuseraddress]=useState('')
    const [getcity,setcity]=useState('')
    const navigate=useNavigate();
    const [getsearchkey,searchkey]=useState([])
    const [logincusid, setlogincusid]=useState('')



    useEffect(()=>{
      const data=(props)
      
      //let result=(data.leftProData)
      searchkey(data.ProData)
    
     // console.warn("Left parentdata is ",result)
    
  },[props])
  
  
  useEffect(()=>{
    let pin=null
    if(localStorage.getItem('user-info'))
    {
     const pinjson=localStorage.getItem('user-info')
       pin=JSON.parse(pinjson).user_areaCode
    }
    const data={pin,getsearchkey}
    const datas=JSON.stringify(data)
    //console.log("dataapi",data)
 //console.log("getsearchkey",getsearchkey)
 //fetch(`85.31.232.240:8080/getproductsarea/${datas}`,
  //fetch(`//allstoresprice.com:3443/getproductsarea/${datas}`,
    fetch(`${window.siteUrl}/getproductsarea/${datas}`).then((result)=> 
   {
     result.json().then((resp)=>{
      setFullData(resp)
      //console.log("slice",resp)
      const data = resp.slice(0,12);
      getpageCount(Math.ceil(resp.length / perPage));
    //console.log("page",Math.ceil(resp.length / perPage))
    setData(data);
       console.log("hlist1",resp)
       
      //  setcount(getplist.length)
        // localStorage.setItem('product-info',JSON.stringify(resp))
        if(localStorage.getItem('user-info'))
  {
    try{
    setcity(resp[0].city)
    
    }
    catch{

    }
    const loc=localStorage.getItem('user-info')
 const parseVal=JSON.parse(loc)
 setloginusernumber(parseVal.user_number)
 setloginusername(parseVal.user_name)
setloginusercity(parseVal.City)
setloginuseraddress(parseVal.address)
setlogincusid(parseVal.cus_id)


  }
     })
   }
   )
 },[getsearchkey])

 const handlePageClick = ({ selected }) => {
  const pagesVisited = selected * perPage;
  const lastSetData = pagesVisited + perPage;
  setData(fullData.slice(pagesVisited, lastSetData));
  window.scrollTo(0, 0);
 }
 

return(
 
  <>
{/* <AmazonCard/> */}
<Container fluid className='my-2 p-2 contheight' style={{backgroundColor: "#f5fff9"}}>
  {getcity!="null"?
  <span className='text-success d-flex justify-content-start'><b>{getcity.toUpperCase()}</b></span>
  :
  null
}
<ReactPaginate
    previousLabel={'prev'}
    nextLabel={"..."+'next'}
    breakLabel={'...'}
    breakClassName={'break-me'}
    
    pageCount={pageCount}
    onPageChange={handlePageClick}
    containerClassName={'pagination'}
    subContainerClassName={'pages pagination'}
    activeClassName={'active'}/>
  
<Row xs={2} md={6} className="g-1 ">
      {/* {Array.from({ length: 40 }).map((_, idx) => ( */}
      {//getplist.map ((item)=>
      data &&
      data.length > 0 &&
      data.map((item, i)=>
      
      
     item.imagetype=='product'?
     
         <Col >
          <Card className='cardheight ' style={{border: "none"}}>
          {/* <Row>
              <Col xs={6}sm={6}>
                </Col>
                <Col xs={6}sm={6}>
                </Col>
               </Row> */}
          <Card.Title className=' my-2 cardtitle'style={{ fontSize: '14px' }}><ToolTip shopname={item.shop_name} mobileN={item.user_number} address={item.address} prodDes={item.product_desc} prodName={item.product_name} prodID={item.product_id} city={item.city}/><div className="vr" />{item.shop_name?item.shop_name.toUpperCase():null}</Card.Title>
          {/* <a href={"/"+item.shopName}> */}
          <Link to ={`/${item.shopName}/${item.product_id}`}
          state={{ itemData: item }}>
          <div className='imageheight '>
             <Card.Img className=' imageh'variant="top" src={item.product_img} /> 
            {/* <Card.Img className=' imageh'variant="top" src="images/1705214695966_deepak.jpg" /> */}
            </div>
            </Link>
           
{/* </a> */}
            <Card.Body className='cardtext'>
              <span className=' beforeprice'>₹{item.product_price}</span>
              <span className='text-danger '>({item.discount}%)</span>
              <span className='text-danger afterdiscount m-1'>₹{item.final_price}</span>
              
             
                <p>{item.product_name} ({item.product_desc})
                
                </p>
              {
                console.log("count",item.id,item.viewcount,item.cus_id)
              }
              {/* <Row>
              <Col xs={6}sm={6}>
              <Likeview id={item.id} view={item.viewcount} cusid={item.cus_id} clickcount={item.clickCount}/>
              </Col>
              <Col xs={6}sm={6}>
              {item.cus_id!=logincusid?
                
                <OrderPlaced sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.final_price}usermobilenumber={loginusernumber}username={item.user_name} cusname={loginusername}useraddress={loginuseraddress}usercity={loginusercity} buyerid={logincusid} address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city} className="m-2"/>
                
                :
                null
}
</Col>
</Row> */}
              </Card.Body>
              
          </Card>
         </Col>:null
      
         
      )}
      
    </Row>
    
    </Container>
    

  </>
    
  
  

  // <Container fluid="md" className='my-2'>
     
  //     <Row className="justify-content-md-center">
  //       <Col xs lg="2" className="justify-content-md-center bg-primary">
  //         1 of 3
  //       </Col>
  //       <Col lg="" className="justify-content-md-center bg-secondary">Variable width content</Col>
  //       <Col xs lg="12">
  //         3 of 3
  //       </Col>
  //     </Row>
  //   </Container>
// {<Container fluid >
//       <Row>
//         <Col lg={8} xs={8}>
//         <CardGroup className='p-1 w-75 '>
//       <Card >
//         <Card.Img variant="top" src={shoping1} />
//         <Card.Body>
//           <Card.Title>Card title</Card.Title>
//           <Card.Text>
//             This 
//             </Card.Text>
//         </Card.Body>
//         <Card.Footer>
//           <small className="text-muted">Last updated 3 mins ago</small>
//         </Card.Footer>
//       </Card>
//       <Card>
//         <Card.Img variant="top" src={shoping1} />
//         <Card.Body>
//           <Card.Title>Card title</Card.Title>
//           <Card.Text>
//             This card 
            
//           </Card.Text>
//         </Card.Body>
//         <Card.Footer>
//           <small className="text-muted">Last updated 3 mins ago</small>
//         </Card.Footer>
//       </Card>
//       <Card>
//         <Card.Img variant="top" src={shoping1} />
//         <Card.Body>
//           <Card.Title>Card title</Card.Title>
//           <Card.Text>
//             This is
         
//           </Card.Text>
//         </Card.Body>
//         <Card.Footer>
//           <small className="text-muted">Last updated 3 mins ago</small>
//         </Card.Footer>
//       </Card>
//       <Card>
//         <Card.Img variant="top" src={shoping1} />
//         <Card.Body>
//           <Card.Title>Card title</Card.Title>
//           <Card.Text>
//             This is 
            
//           </Card.Text>
//         </Card.Body>
//         <Card.Footer>
//           <small className="text-muted">Last updated 3 mins ago</small>
//         </Card.Footer>
//       </Card>
//     </CardGroup>
        
        
//         </Col>
       
//       </Row>
//       </Container> 
      
  
    
)
}