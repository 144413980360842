
import { useState,useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import { NavLink,Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import { Button } from 'react-bootstrap';

export default function Ordercount()
{
    //const siteUrl="http://localhost:4500";
    //const siteUrl="//allstoresprice.com:3443";

    const navigate=useNavigate();
    const [getordercount,setordercount]=useState(0)
    const [getuserid,setuserid]=useState('')
    const [getstatus,setstatus]=useState('')
    
    useEffect(()=>{
        if(localStorage.getItem('user-info')){
          let userdata=localStorage.getItem('user-info')
          let parseVal=JSON.parse(userdata)
          let buyerid= parseVal.cus_id
          setuserid(buyerid)
          //console.log("orderid",buyerid)
  
  
  fetch(`${window.siteUrl}/ordercount/${buyerid}`,
        //fetch(`//allstoresprice.com:3443/showorderproduct/${buyerid}`,
        {
          headers: {
          authorization:'bearar'+' '+localStorage.getItem('auth')
        }
      })
        .then((result)=> 
       {
         result.json().then((resp)=>{
            setordercount(resp.count)
           console.log("orderlist",resp.count)
          
         })
       }
       )
      }
     },[])

    function Orderupdate()

    {
     let userid=getuserid
 console.log("userid",userid)
    
 fetch(`${window.siteUrl}/updateorderstatus/${userid}`,
        //fetch(`//allstoresprice.com:3443/showorderproduct/${buyerid}`,
        {
          method:'POST',
          headers: {
          authorization:'bearar'+' '+localStorage.getItem('auth')
        }
      })
        .then((result)=> 
       {
         result.json().then((resp)=>{
            
           console.log("orderlist",resp)
           navigate('/order')
          
         })
       }
       )
      }
    return(
<div>
      <Nav.Link onClick={Orderupdate}>Order<Badge bg="secondary">{getordercount}</Badge></Nav.Link >
      </div>

    )
}