import { useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';

export default function ProductDetails(props){

    const location = useLocation();
    const itemData = location.state?.itemData;

    console.log("productdata",itemData)
   

return(
    <Container fluid='md' className='my-3 productDetails'>
    
    <Row>
          <Col xs={0}sm={3} ></Col>
          <Col xs={12}sm={6} >
       <div >
             <Card.Img className=' imagehorder'variant="top"  src={`/${itemData.product_img}`} roundedCircle/>
      
    </div>
    <div>
     <InputGroup className=" my-2 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Shop Name</InputGroup.Text>
     <label for="name" className="form-control my-1" id="shopName">{itemData.shop_name}</label> 
     </InputGroup>
	   </div>
       <div>
        <InputGroup className=" my-2 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Area</InputGroup.Text>
	      <label for="name" className="form-control my-1 p-2" id="productname">{itemData.city}</label> 
       </InputGroup>
		  </div>
       <div>
     <InputGroup className=" my-2 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Description</InputGroup.Text>
     <label for="name" className="form-control my-1" id="shopName">{`${itemData.product_desc}`}</label> 
     </InputGroup>
	   </div>

        
      {/* <div className="form-group my-1">
	   <label for="name" className="form-control" id="areaPin">{`${props.userareacode}`}</label> 
	   </div> */}
        {itemData.imagetype=='product'?
     <div>
     <InputGroup className=" my-2 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Price</InputGroup.Text>
     <label for="name" className="form-control my-1" id="price">{`₹ ${itemData.product_price}`}</label> 
     </InputGroup>
	   </div>:
        null
     }
     
     
        {/* <FloatingLabel controlId="floatingTextarea2" label="Enter your Order Details">
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here" 
          style={{ height: '120px' }}>{``}</Form.Control>
        
      </FloatingLabel> */}
      </Col>
      <Col xs={0}sm={3} ></Col>
    </Row>
    </Container>
)
}